export default [
  {
    section: 'hero',
    theme: 'blue'
  },
  {
    section: 'textChat',
    theme: 'white'
  },
  {
    section: 'smsMkt',
    theme: 'white'
  },
  {
    section: 'videoCalls',
    theme: 'white'
  },
  {
    section: 'reviewAlert',
    theme: 'blue'
  },
  {
    section: 'reviewBeacon',
    theme: 'blue'
  },
  {
    section: 'clients',
    theme: 'mixed'
  },
  {
    section: 'footer',
    theme: 'footer'
  },
]