import AOS from 'aos'

export default () => {

  AOS.init({
    disable: 'mobile',
    once: true
  })

  const currentPage = document.body.className
  
  if (currentPage === 'home' || currentPage === 'products') {
    const aosAnimation = document.querySelectorAll('.aos');
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.intersectionRatio > 0) {
          entry.target.classList.add('aos-animate');
        } else {
          entry.target.classList.remove('aos-animate');
        }
      })
    })
    aosAnimation.forEach(aosObject => {
      observer.observe(aosObject);
    })
  }

}