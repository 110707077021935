import { CountUp } from 'countup.js'
import anime from 'animejs/lib/anime.es.js'

let textChatIsFirstLoad = true
let progressIsFirstLoad = true

export default (sectionInView) => {
  
  // text chat counter
  if (sectionInView === 'leadSection') {
    if (textChatIsFirstLoad === true) {
      setTimeout(() => {      
        var numAnim = new CountUp('js-lead-number', 37)
        numAnim.start()
      }, 1000)
    }
    
    textChatIsFirstLoad = false
  }

  if (sectionInView === 'progressBarSection') {
    const bars = Array.from(document.querySelectorAll('.js-progress-bar'))
    const progressValue = Array.from(document.querySelectorAll('.js-progress-value'))

    setTimeout(() => {
      bars.forEach((bar) => {
        bar.classList.add('is-loaded')
      })

      if (progressIsFirstLoad === true) {
        progressValue.forEach((valueItem) => {
          var numAnim = new CountUp(valueItem, valueItem.dataset.value)
          numAnim.start()
        })
      }

    }, 1000)

  }

}