import homeSections from '../data/home-sections'
import homeComps from '../compositions/home-comps'
export default () => {
  if (document.body.classList.contains('home')) {

    const sections = Array.from(document.querySelectorAll('section'))
    
    let options = {
      root: document.querySelector('.js-scroll'),
      rootMargin: '50% 0%',
      threshold: 1.0
    }

    function handleIntersection (entries) {
      entries.map((entry) => {
        if (entry.isIntersecting) {
          homeSections.map(item => {
            if (entry.target.id === item.section) {
              document.body.dataset.theme = item.theme;
              document.body.dataset.section = item.section;
              homeComps(item.section)
            }
          })
        }
      })
    }
    
    let observer = new IntersectionObserver(handleIntersection, options)

    sections.map(section => {
      observer.observe(section)
    })

  }
}