import 'intersection-observer'
import aos from './aos'
import rellax from './rellax'
import home from './pages/home'
import products from './pages/products'
import single from './pages/single'
import hamburger from './components/hamburger'
import form from './components/form'

export default () => {
  aos()
  rellax()

  // Pages
  home()
  single()
  products()

  // Components
  hamburger()
  form()
}