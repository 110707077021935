export default [
  {
    section: 'hero',
    theme: 'white'
  },
  {
    section: 'reputation',
    theme: 'blue'
  },
  {
    section: 'review',
    theme: 'blue'
  },
  {
    section: 'beacon',
    theme: 'blue'
  },
  {
    section: 'relationship',
    theme: 'blue'
  },
  {
    section: 'textChat',
    theme: 'blue'
  },
  {
    section: 'videoCalls',
    theme: 'blue'
  },
  {
    section: 'smsMkt',
    theme: 'blue'
  },
  {
    section: 'relationship2',
    theme: 'white'
  },
  {
    section: 'final',
    theme: 'white'
  },
  {
    section: 'footer',
    theme: 'footer'
  }
]