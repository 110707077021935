import singleComps from '../compositions/single-comps'

export default () => {
  if (document.body.classList.contains('single')) {
    
    const sections = Array.from(document.querySelectorAll('section'))
    const footer = document.querySelector('#footer')

    let options = {
      rootMargin: '50% 0%',
      threshold: 1.0
    }

    function handleIntersection (entries) {
      entries.map((entry) => {
        if (entry.isIntersecting) {
          singleComps(entry.target.id)
          if (entry.target.id === 'footer') {
            document.body.dataset.theme = 'footer';
          } else {
            document.body.dataset.theme = 'blue';
          }
        }
      })
    }
    
    const observer = new IntersectionObserver(handleIntersection, options)
    observer.observe(footer)

    sections.map(section => {
      observer.observe(section)
    })
    
  }
}