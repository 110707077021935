import Typed from 'typed.js'
import anime from 'animejs/lib/anime.es.js'

let heroIsFirstLoad = true

export default (sectionInView) => {
  // hero animation
  if (sectionInView === 'hero') {
    if (heroIsFirstLoad === true) {
      var heroTl = anime.timeline({
        easing: 'easeOutExpo',
        duration: 3000
      })
    
      heroTl
        .add({
          targets: '.js-agent-row',
          opacity: 1,
          delay: 1000,
          begin: (anim) => {
            new Typed('.js-agent-message', {
              // Waits 1000ms after typing "First"
              startDelay: 1300,
              strings: ['Hello Mary, the repair of your watch has been completed.'],
              showCursor: false,
              typeSpeed: 20
            })
          }
        })
        .add({
          targets: '.js-costumer-row',
          opacity: 1,
          begin: (anim) => {
            new Typed('.js-costumer-message', {
              // Waits 1000ms after typing "First"
              startDelay: 700,
            strings: ['Wow, how fast! Can I pick it up today?'],
              showCursor: false,
              typeSpeed: 20
            })
          }
        })
        .add({
          targets: '.js-hero-bubbles',
          delay: 500,
          opacity: 0,
          duration: 500
        })
        .add({
          targets: ['.js-home-hero-browser', '.js-home-hero-comment'],
          opacity: 1,
          duration: 500,
          delay: anime.stagger(300)
        })
    }

    heroIsFirstLoad = false
  }
}