import productsSections from '../data/products-sections'

export default () => {
  if (document.body.classList.contains('products')) {
    const sections = Array.from(document.querySelectorAll('section'))
    const scroll = document.querySelector('.js-scroll')
    const progressEl = document.querySelector('.js-progress')
    const indicatorEl = document.querySelector('.js-indicator')
    
    let options = {
      root: scroll,
      rootMargin: '50% 0%',
      threshold: 1.0
    }

    function handleIntersection (entries) {
      entries.map((entry) => {
        if (entry.isIntersecting) {
          productsSections.map(item => {
            if (entry.target.id === item.section) {
              document.body.dataset.theme = item.theme;
              document.body.dataset.section = item.section;
            }
          })
        }
      })
    }
    
    let observer = new IntersectionObserver(handleIntersection, options)

    sections.map(section => {
      observer.observe(section)
    })

    scroll.addEventListener('scroll', function(e) {
      const scrollPercentage = 100 * scroll.scrollTop / ((scroll.scrollHeight - scroll.clientHeight ) - scroll.clientHeight)

      progressEl.setAttribute(`style`, `height: ${scrollPercentage}%;`)
      indicatorEl.setAttribute(`style`, `top: ${scrollPercentage}%`)
    })
  }
}