export default () => {
	var modal = document.querySelector('.txt-modal')
	var btn = document.querySelectorAll('.--cta')
	var overlay = document.querySelector('.overlay')
	var closeModal = document.querySelector('.txt-modal-close')


	for (var i = 0; i < btn.length; i++) {
	  btn[i].addEventListener('click', function(event) {
	  	event.preventDefault();
	    modal.classList.add('fade-in')
		  overlay.classList.add('fade-in')
		  setTimeout(()=>{
	        modal.style.display = 'block';
	        overlay.style.display = "block";

	        modal.classList.remove('fade-out')
		  		overlay.classList.remove('fade-out')
	    },300)
	  });
	}

	overlay.onclick = function() {
	  modal.classList.add('fade-out')
	  overlay.classList.add('fade-out')
	  setTimeout(()=>{
        modal.style.display = 'none';
        overlay.style.display = "none";

        modal.classList.remove('fade-out')
	  		overlay.classList.remove('fade-out')
    	},300)

	}

	closeModal.onclick = function() {
	  modal.classList.add('fade-out')
	  overlay.classList.add('fade-out')
	  setTimeout(()=>{
        modal.style.display = 'none';
        overlay.style.display = "none";

        modal.classList.remove('fade-out')
	  		overlay.classList.remove('fade-out')
    	},300)

	}
	window.onclick = function(event) {
	  if (event.target == modal) {
	    modal.classList.add('fade-out')
		  overlay.classList.add('fade-out')
		  setTimeout(()=>{
	        modal.style.display = 'none';
	        overlay.style.display = "none";

	        modal.classList.remove('fade-out')
	  			overlay.classList.remove('fade-out')
	    },300)
	  }
	}

	// Send form
	var submitForm = document.querySelector('.txt-form-button');
	submitForm.onclick = function(e) {

	    e.preventDefault();

	    var data = [{
	            name: "email",
	            value: document.querySelector("#email").value
	        },
	        {
	            name: "firstname",
	            value: document.querySelector("#name").value
	        },
	        {
	            name: "phone",
	            value: document.querySelector("#phone").value
	        },
	        {

	            name: "company",
	            value: document.querySelector("#company").value
	        }

	    ];


	    var json_value = {
	        "fields": data,
	        "skipValidation": false,

	        "context": {
	            "pageUri": window.location.href,
	            "pageName": document.title
	        },
	    };

	    console.log(json_value);

	    fetch("https://api.hsforms.com/submissions/v3/integration/submit/2806233/a12fd983-8c37-4a7b-b79b-fd7444bf5e17", {
	        method: "POST",
	        headers: {
	            'Content-Type': 'application/json'
	        },
	        body: JSON.stringify(json_value)
	    }).then(res => {
  		  console.log("Form sent:", res);
				document.querySelector('.txt-form').style.display = "none";
				document.querySelector('.txt-form-response').innerHTML = "<h2>Thank you<\/h2><br><p>We will contact you soon.<\/p>";
	    }).catch((error) => {
			  console.error('Error:', error);
			  document.querySelector('.txt-form').style.display = "none";
				document.querySelector('.txt-form-response').innerHTML = "<p>Unable to send your message, please try again later.<\/p>";
			});




	};


}
