import Rellax from 'rellax'

export default () => {

  new Rellax('.rellax', {
    wrapper: '.js-scroll',
    center: true,
  })

  new Rellax('.rellax', {
    center: true,
  })
}